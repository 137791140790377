.nav-bar {
    padding: 1em 2em;
    position: fixed;
    top: 0;
    z-index: 100;
    font-family: 'Courier New', monospace;
    background-color: #eee;
}

.nav-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.nav-logo-text {
    font-size: 1.3rem;
    font-weight: bold;
    color: #555;
    text-decoration: none;
}

.nav-logo-text:hover {
    color: #a50;
}

.nav-logo-text:visited {
    color: #555;
}

.nav-menu {
    list-style: none;
    padding: 0;
    box-sizing: border-box;
    font-weight: 600;
    margin: 0;
}

.nav-menu-item {
    list-style: none;
    display: inline-block;
}

.nav-item-text {
    text-decoration: none;
    padding: 0.5em 1em;
    color: #555;
}

.nav-button {
    text-decoration: none;
    color: #555;
    padding: 1em 1em;
    font-size: 1em;
    white-space: nowrap;
    outline: none;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    position: relative;
    top: 0;
}

.nav-button:visited {
    color: #555;
    text-decoration: none;
}

.nav-button:hover {
    color: rgba(170, 85, 0, 0.62);
    font-weight: bolder;
    top: 4px;
    transition-duration: 150ms;
}

.social-bar {
    margin-right: 1em;
    align-self: center;
    color: #555;
}

.social-bar img {
    margin-right: 0.5em;
}

.social-icon-link {
    margin-left: 0.25em;
    margin-right: 0.25em;
    color: #555;
    position: relative;
    top: 0;
}

.social-icon-link:visited {
    color: #555;
}

.social-icon-link:hover {
    color: #a50;
    top: 4px;
    transition-duration: 150ms;
}

.social-bar a:hover {
    color: #A50;
}
