.swiper {
    width: 100%;
    height: 100%;
    --swiper-theme-color: #222;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    align-self: center;
    padding-bottom: 3em;
    padding-left: 3em;
    padding-right: 3em;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.gallery-description {
    margin-top: 2em;
}

.swiper-slide img {
    display: block;
    width: 100%;
    max-height: 720px;
    object-fit: contain;
}

.swiper-slide video {
    display: block;
    width: 100%;
    max-height: 720px;
    object-fit: contain;
}
