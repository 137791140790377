h5 {
    font-size: 1em;
}

h3 {
    margin-top: 1.5em;
    margin-bottom: 0.25em;
}

.About-Text {
    color: #555;
    padding-bottom: 0.5em;
}

.About-Contact-Link-Container {
    margin-top: 2.5em;
}

.About-Contact-Link {
    font-family: 'Courier New', monospace;
    text-decoration: none;
    font-weight: 800;
    line-height: 3em;
    padding: 1.5em 2.5em;
    border-radius: 8px;
    margin-left: 1em;
    margin-right: 1em;
    background: rgba(170, 85, 0, 0.15);
    transition-duration: 250ms;
}

.About-Contact-Link:hover {
    background: rgba(170, 85, 0, 0.25);
    transition-duration: 250ms;
    margin-left: 1.25em;
    margin-right: 0.75em;
    color: #a50;
}

.Portrait {
    width: 20vw;
    height: 20vw;
    max-width: 240px;
    max-height: 240px;
    border-radius: 999em;
    aspect-ratio: 1;
}

.technology-link-wrapper {
    font-family: 'Courier New', monospace;
    font-size: 0.8em;
    display: inline-block;
    width: 25%;
    vertical-align: top;
}

.technology-link-wrapper > a {
    color: #000;
    font-weight: bold;
    text-decoration: none;
    width: 100%;
    position: relative;
    left: 0;
}

.technology-link-wrapper > a:hover {
    text-decoration: underline;
    left: 8px;
    transition-duration: 250ms;
}

.technology-link {
    display: block;
    position: relative;
    border-radius: 10em;
    margin-bottom: 10px;
    padding: 0.25em
}

.associate-card-wrapper {
    width: calc(50% - 2em);
    display: inline-block;
    vertical-align: top;
    margin: 1em 0 1em 1em;
    border-top: 3px solid rgba(170, 85, 0, 0.25);
}

.associate-card-wrapper:not(:last-of-type) {
    margin: 1em 1em 1em 0;
}

.associate-card-wrapper:hover {
    border-top: 6px solid rgba(170, 85, 0, 0.25);
    transition-duration: 150ms;
    margin-bottom: calc(1em - 3px);
}

@media screen and (max-width: 800px) {
    .associate-card-wrapper {
        width: 100%;
        margin: 1em 0;
    }
}

.associate-card {
    padding: 1.5em;
}

.associate-card:not(:last-of-type) {
    padding-left: 0;
    padding-right: 1em;
}

.associate-card h5 {
    font-family: 'Exo', sans-serif;
    margin-bottom: 0;
    padding-bottom: 0;
}

.associate-site-link {
    font-size: 1.4em;
    line-height: 1.4em;
    margin-top: -0.4em;
    padding-left: 1em;
}

@media screen and (max-width: 600px) {
    .About-Contact-Link-Container {
        margin-top: 1em;
        padding-bottom: 1.75em;
    }

    .associate-card {
        padding: 1.25em;
    }

    .technology-link-wrapper {
        width: 50%;
        max-width: 50%;
        padding-top: 0;
    }
}
