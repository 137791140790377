body {
  margin: 0;
  font-family: 'Exo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  margin-bottom: 8em;
}

.navbar-light .nav-item.active .nav-link,
.navbar-light .nav-item .nav-link:active,
.navbar-light .nav-item .nav-link:focus,
.navbar-light .nav-item:hover .nav-link {
  background-color: transparent;
}

.nav-item a {
  font-family: 'Courier New', monospace;
  font-weight: bold;
  color: #555;
  float: left;
}

.nav-item a:visited {
  color: #555;
}

.nav-item a:hover {
  text-decoration: underline;
}

/*
    Reusable styles, element styles
 */

.text-right {
  text-align: right;
}

.courier {
  font-family: 'Courier New', monospace;
}

.decorated {
  overflow: hidden;
  text-align: left;
}

.decorated > span {
  position: relative;
  display: inline-block;
}

.decorated > span:before, .decorated > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 2px solid #555;
  width: 100vw;
  margin: 0 20px;
}

.decorated > span:before {
  right: 100%;
}

.decorated > span:after {
  left: 100%;
}

.bottom-spaced {
  margin-bottom: 1em;
}

.bottom-spaced-lg {
  margin-bottom: 4em;
}

.top-spaced {
  margin-top: 2em;
}

.top-spaced-lg {
  margin-top: 4em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Courier New', monospace;
  font-weight: bold;
}

h1 {
  color: #555;
}

h2, h3 {
  color: #555;
}

a {
  color: #a50;
}

a:visited {
  color: #a50;
}

p {
  line-height: 1.8em;
}

fieldset {
  border: none !important;
  outline: none !important;
}

.Section {
  margin: auto;
  padding-top: 7em;
  padding-bottom: 5em;
  width: 75vw;
  max-width: 1000px;
  min-width: 292px;
  display: block;
  flex-direction: column;
  align-items: center;
}
