.Contact {
    color: #555;
}

.Contact-Form-Submit-Button {
    border: none;
    font-family: 'Courier New', monospace;
    text-decoration: none;
    font-weight: 800;
    line-height: 3em;
    font-size: 1.25em;
    padding: 0.25em 2.75em;
    border-radius: 8px;
    background-color: rgba(124, 173, 107, 0.8);
    color: #fff;
    transition-duration: 250ms;
}

.Contact-Form-Submit-Button:hover {
    background-color: rgba(124, 173, 107, 1.0);
    color: #fff;
    transition-duration: 250ms;
    margin-left: 1.25em;
}

.Contact-Form-Sent-Area {
    border-left: 4px solid #555;
    padding-left: 1em;
    line-height: 2em;
    font-size: 1.1em;
    margin-top: 1em;
    margin-bottom: 1em;
}
