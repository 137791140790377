/*
    Project Archive header area styles
*/

.Nav-Offset-Gap {
    height: 5em;
    width: 100%;
}

.Project-Row-Header-Filter-Area {
    margin-bottom: 2em;
    justify-content: space-between;
}

.Project-Row-Header-Filter-Area a {
    padding-left: 1em;
    color: #555;
    display: inline-flex;
    margin-bottom: 0.5em;
}

.Project-Row-Header-Filter-Area a:visited {
    color: #555;
}

.Project-Row-Header-Filter-Area a:hover {
    color: rgba(170, 85, 0, 1);
}

.selected-filter {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 2em;
    position: relative;
    color: #fff;
}

.selected-filter:after {
    transform: translateX(-50%);
    border-radius: 100%;
    position: absolute;
    background-color: #555;
    bottom: 10px;
    height: 8px;
    content: '';
    width: 8px;
    left: calc(50% + 6px);
}

.Project-Display-Type-Icon {
    margin-left: 0.5em;
    color: #555;
    font-size: 2em;
    margin-top: -0.25em;
    padding: 0.2em;
    overflow: visible;
    border-radius: 50%;
}

.Project-Display-Type-Icon-Selected {
    background-color: rgba(0, 0, 0, 0.075);
}

.Project-Display-Type-Icon:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

/*
    Project Card styles
*/

.Project-Card-Container {
    width: calc(50% - 2em);
    vertical-align: top;
    display: inline-flex;
    padding: 1em 1em 1em 1em;
}

.Project-Card-Container:hover {
    padding: 2em 0.25em 0 1.75em;
    transition-duration: 150ms;
}

.Project-Card-Content {
    padding: 1em;
    background-color: rgba(255, 255, 255, 0.55);
    transition-duration: 150ms;
}

.Project-Card-Content:hover {
    background-color: rgba(255, 255, 255, 0.75);
}

.Project-Card-Content p {
    margin-top: 1em;
}

.Project-Card-Subtitle {
    font-size: 1.15em;
    padding-top: 0.25em;
}

.Project-Card-Icon-Link {
    padding-left: 1em;
}

.Project-Card-Stack-Area {
    margin-top: 1em;
    border-top: 1px solid #ccc;
    padding: 1em;
    font-family: 'New Courier', monospace;
    text-align: center;
}

.Project-Card-Stack-Area span {
    padding: 0.25em;
}

.Project-Card-Stack-Area span:not(:first-child):before {
    content:  " \B7  ";
}

/*
    Project Row styles
 */

.Project-Row-Icon-Link {
    display: block;
    font-family: 'Courier New', monospace;
    font-size: 1.5em;
    text-decoration: none;
    color: #555;
    padding: 0.25em 0.5em;
    outline: none;
}

.Project-Row-Icon-Link:visited {
    color: #555;
}

.Project-Row-Icon-Link:hover {
    color: #888;
}

.Project-Row-Header {
    margin-top: 3em;
    padding-bottom: 1em;
    text-transform: uppercase;
}

.Project-Row-Stack-Item {
    font-family: 'Courier New', monospace;
    text-align: center;
}

/*
    Favorite Projects area styles
 */

.Favorites-Description-Heading-Area > h3 {
    font-size: 1.25em;
    padding-bottom: 0.25em;
}

.Favorites-Icon-Links {
    display: inline-block;
    align-self: center;
    justify-content: center;
    justify-self: center;
    padding-right: 2.25em;
    padding-left: 2em;
}

.Favorites-Icon-Link {
    display: block;
    padding: 0.15em 0.25em;
    font-size: 1.75em;
    border-radius: 50%;
    text-align: center;
    position: relative;
}

.Favorites-Icon-Link:hover > *:last-child {
    left: 0.25em;
    top: -1.35em;
    transition-duration: 150ms;
}


.Favorites-Icon-Link:hover > *:not(:last-child) {
    left: -0.15em;
    top: -0.15em;
    transition-duration: 150ms;
}

.Favorites-Icon-Link-Small-Background {
    position: relative;
    top: -1.5em;
    left: 0.125em;
    width: 1.75em;
    height: 1.75em;
    padding-top: 0.1em;
    border-radius: 50%;
    background-color: rgba(170, 170, 170, 0.5);
    z-index: 1;
}

.Favorites-Award-Icon > * {
    color: #fff;
}

.Favorites-Icon {
    position: relative;
    top: 0;
    left: 0;
    width: 1.75em;
    height: 1.75em;
    padding-top: 0.1em;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 2;
}

.Favorites-Icon > * {
    color: #aaa;
}

.Favorites-Award-Icon {
    position: relative;
    top: 0;
    left: 0;
    width: 1.75em;
    height: 1.75em;
    padding-top: 0.1em;
    border-radius: 50%;
    background-color: rgba(215, 178, 52, 0.8);
    z-index: 2;
}

.Favorites-Award-Icon > * {
    color: #fff;
}

.Favorites-Area-Smaller {
    flex: 0.5;
    padding: 1em;
    display: inline-flex;
    align-self: center;
}

.Favorites-Area-Larger {
    flex: 1;
    padding: 1em;
    justify-content: end;
    align-content: center;
    align-self: center;
}

.Favorites-CUDA {
    margin-top: 6em;
    margin-bottom: 6em;
    width: 100%;
    justify-content: center;
    display: flex;
}

.Favorites-CUDA-Image-Area {
    width: 100%;
    justify-content: flex-end;
    align-content: end;
    justify-items: flex-end;
}

.Favorites-CUDA-Image-Area img {
    border: 4px solid rgba(255, 255, 255, 1);
    border-radius: 0.25em;
    width: 13vw;
    max-width: 500px;
    aspect-ratio: auto;
    float: right;
}

.Favorites-CUDA-Text-Area {
    display: flex;
    margin-left: 1em;
}

.Favorites-CUDA-Text-Area h3 {
    line-height: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.Favorites-CUDA-Text-Area p {
    padding: 1.5em;
    background-color: #fff;
    border-radius: 0.25em;
    margin-top: 2em;
}

.Favorites-SMLR {
    margin-top: 6em;
    margin-bottom: 6em;
    width: 100%;
    justify-content: center;
    display: flex;
}

.Favorites-SMLR-Image-Area {
    height: 100%;
}

.Favorites-SMLR-Image-Area img {
    border: 4px solid rgba(255, 255, 255, 1);
    border-radius: 0.25em;
    width: 30vw;
    max-width: 500px;
}

.Favorites-SMLR-Text-Area {
    display: flex;
}

.Favorites-SMLR-Text-Area p {
    padding: 1.5em;
    background-color: #fff;
    border-radius: 0.25em;
    margin-top: 2em;
    margin-right: 1em;
}

.Favorites-CHROM-TUNER {
    margin-top: 6em;
    width: 100%;
    justify-content: center;
    display: flex;
}

.Favorites-CHROM-TUNER-Image-Area img {
    border: 4px solid rgba(255, 255, 255, 1);
    border-radius: 0.25em;
    max-width: 30vw;
}

.Favorites-CHROM-TUNER-Text-Area {
    display: flex;
    margin-left: 1em;
}

.Favorites-CHROM-TUNER-Text-Area h3 {
    line-height: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.Favorites-CHROM-TUNER-Text-Area p {
    padding: 1.5em;
    background-color: #fff;
    border-radius: 0.25em;
    margin-top: 2em;
}



/*
    Styles for different screen sizes
 */

@media screen and (max-width: 1500px) {
    .Favorites-CUDA-Image-Area img {
        width: 15vw;
        max-width: 400px;
    }
}

@media screen and (max-width: 1200px) {
    .Favorites-CUDA-Image-Area img {
        width: 20vw;
        max-width: 400px;
    }
}

@media screen and (max-width: 1000px) {
    .Favorites-CUDA {
        display: block;
    }

    .Favorites-CUDA > div {
        justify-content: center;
        align-content: center;
        justify-self: center;
        margin: auto;
        width: 100%;
    }

    .Favorites-CUDA-Text-Area {
        margin-right: 1em;
    }

    .Favorites-CUDA-Image-Area {
        align-content: center;
        align-self: center;
        justify-content: center;
        display: inline-flex;
    }

    .Favorites-CUDA-Image-Area img {
        width: 275px;
        max-width: 275px;
        max-height: 50%;
        align-self: center;
        justify-self: center;
        float: none;
    }

    .Favorites-SMLR {
        display: block;
    }

    .Favorites-CHROM-TUNER {
        display: block;
    }

    .Favorites-SMLR > div {
        justify-content: center;
        align-content: center;
        justify-self: center;
        margin: auto;
        width: 100%;
        display: block;
    }

    .Favorites-CHROM-TUNER > div {
        justify-content: center;
        align-content: center;
        justify-self: center;
        margin: auto;
        width: 100%;
    }

    .Favorites-SMLR-Image-Area img {
        border: 4px solid rgba(255, 255, 255, 1);
        border-radius: 0.25em;
        width: 100%;
        max-width: 100%;
    }

    .Favorites-CHROM-TUNER-Image-Area img {
        border: 4px solid rgba(255, 255, 255, 1);
        border-radius: 0.25em;
        width: 100%;
        max-width: 100%;
    }

    .Favorites-SMLR-Text-Area p {
        margin-right: 0;
    }

    .Favorites-CHROM-TUNER-Text-Area {
        margin-left: 0;
    }
}

@media screen and (max-width: 900px) {
    .Project-Card-Container {
        width: 100%;
    }

    .Favorites-Icon-Links {
        padding-right: 1.25em;
        padding-left: 1em;
    }
}

@media screen and (max-width: 600px) {
    .Favorites-CUDA-Text-Area {
        padding: 0;
        margin: 0;
        font-size: 0.85em;
    }

    .Favorites-SMLR-Text-Area {
        padding: 0;
        margin: 0;
        font-size: 0.85em;
    }

    .Favorites-CHROM-TUNER-Text-Area {
        padding: 0;
        margin: 0;
        font-size: 0.85em;
    }

    .Favorites-Icon-Links {
        padding-right: 0em;
        padding-left: 0.75em;
    }

    .Favorites-Area-Smaller {
        padding: 0;
    }

    .Favorites-Area-Larger {
        padding: 0;
    }
}

@media screen and (max-width: 1000px) {
    .Project-Card-Container {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 500px) {
    .Favorites-CUDA-Image-Area img {
        width: 100%;
        margin: 3em;
        max-width: 100%;
        align-self: center;
        justify-self: center;
        float: none;
    }
}
